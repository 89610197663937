import * as React from "react";

import * as reflection from "assets/md/overall-reflection.md";
import Container from "components/common/Container";
import Text from "components/common/Text";
import Markdown from "components/layout/Markdown";

const Reflection = () => {
	return (
		<Container>
			<Text variant="display2" align="center" gutterBottom>Overall Reflection</Text>
			<Markdown source={reflection} />
		</Container>
	)
};

export default Reflection;
