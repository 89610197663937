import * as React from "react";
import { Document, Page, Pdf } from "react-pdf/dist/entry.webpack";

import { Button, createStyles, Theme, WithStyles, withStyles, WithTheme, withTheme } from "@material-ui/core";
import withWidth, { WithWidth } from "@material-ui/core/withWidth";
import cn from "classnames";
import ChevronLeft from "mdi-material-ui/ChevronLeft";
import ChevronRight from "mdi-material-ui/ChevronRight";
import OpenInNew from "mdi-material-ui/OpenInNew";

import Loading from "components/common/Loading";

const common = {
	sideWidth: 60,
	sidePadding: 2
};

const styles = ({ spacing, breakpoints, palette }: Theme) => createStyles({
	wrapper: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		position: "relative",
		padding: `0 ${common.sideWidth}px`,
		marginBottom: spacing.unit * 4,
		"&:hover $button": {
			opacity: 1
		}
	},
	noPadding: {
		padding: 0
	},
	document: {
		position: "relative"
	},
	page: {
		display: "inline-block",
		//	position: "absolute !important" as any, // !important doesn't play nice with TS :(
		// top: 0,
		// left: 0,
		// bottom: 0,
		// right: 0,
		margin: "0 auto",
		borderRadius: "2px"
	},
	pageOutline: {
		border: `1px solid ${palette.grey["300"]}`,
		boxShadow: `
			0px 3px 5px -1px rgba(0, 0, 0, 0.2),
			0px 5px 8px 0px rgba(0, 0, 0, 0.14),
			0px 1px 14px 0px rgba(0, 0, 0, 0.12)`
	},
	button: {
		opacity: 0,
		transition: "opacity .3s ease",
		zIndex: 100
	},
	controlButtonWrap: {
		position: "absolute",
		top: 0,
		bottom: 0,
		width: common.sideWidth,
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		paddingBottom: spacing.unit * common.sidePadding,
		"& > $button": {
			width: common.sideWidth - spacing.unit * common.sidePadding,
			height: common.sideWidth - spacing.unit * common.sidePadding
		}
	},
	alignBottom: {
		alignItems: "flex-end"
	},
	openButton: {
		position: "absolute",
		top: spacing.unit * 2,
		right: spacing.unit * 2
	},
	wrapLeft: {
		left: 0,
		paddingLeft: spacing.unit * common.sidePadding,
		// paddingRight: spacing.unit * 1
	},
	wrapRight: {
		right: 0,
		// paddingLeft: spacing.unit * 1,
		paddingRight: spacing.unit * common.sidePadding
	}
});

type OwnProps = {
	file: string,
	type: "document" | "slides"
};

type Props = OwnProps & WithStyles<typeof styles> & WithTheme & WithWidth;
type State = {
	page: number,
	prevPage: number,
	pageCount: number
};

class ViewPDF extends React.Component<Props, State> {
	state: State = {
		page: 0,
		prevPage: -1,
		pageCount: null
	};

	contentRef = React.createRef<HTMLDivElement>();

	onPageChange = (by: number) => {
		this.setState(s => ({
			page: s.page + by,
			prevPage: s.page // TODO remove if i can't find a way to use this better
		}));
	}
	onPrevPage = () => { this.onPageChange(-1); }
	onNextPage = () => { this.onPageChange(+1); }

	onDocumentLoad = ({ numPages }: Pdf) => {
		this.setState({ pageCount: numPages });
	}

	render() {
		const { file, type, classes, theme, width } = this.props;
		const { page, prevPage, pageCount } = this.state;
		const showControls = pageCount > 1;
		const pageWidth = this.contentRef.current !== null ? this.contentRef.current.clientWidth : null;

		const wrapperClasses = cn(classes.wrapper, {
			[classes.noPadding]: true //!showControls || type === "document"
		});

		const pageClasses = cn(classes.page, {
			[classes.pageOutline]: true //type === "document"
		});

		const controlClasses = cn(classes.controlButtonWrap, {
			[classes.alignBottom]: type === "slides"
		});

		return (
			<div className={wrapperClasses} ref={this.contentRef}>
				<Document className={classes.document} file={file} onLoadSuccess={this.onDocumentLoad} loading={<Loading />}>
					<Page pageIndex={page} className={pageClasses} width={pageWidth} />
				</Document>
				{
					showControls ? (
						<>
							<div className={cn(controlClasses, classes.wrapLeft)}>
								<Button className={classes.button} onClick={this.onPrevPage} variant="fab" disabled={page === 0}>
									<ChevronLeft />
								</Button>
							</div>
							<div className={cn(controlClasses, classes.wrapRight)}>
								<Button className={classes.button} onClick={this.onNextPage} variant="fab" disabled={page === pageCount - 1}>
									<ChevronRight />
								</Button>
							</div>
						</>
					) : null
				}
				<Button className={cn(classes.openButton, classes.button)} href={file} variant="fab" color="secondary" target="_blank">
					<OpenInNew />
				</Button>
			</div>
		);
	}
}

export default withWidth()(
	withTheme()(
		withStyles(styles)(
			ViewPDF
		)
	)
);
