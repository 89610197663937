import * as React from "react";

import * as slides from "assets/group-presentation-slides.pdf";
import * as reflection from "assets/md/group-presentation-reflection.md";
import Container from "components/common/Container";
import Text from "components/common/Text";
import ViewPDF from "components/common/ViewPDF";
import Markdown from "components/layout/Markdown";

const GroupSpeech = () => {
	return (
		<Container>
			<Text variant="display2" align="center" gutterBottom>Group Presentation</Text>
			<Text variant="title" align="left" gutterBottom>Sample Work</Text>
			<ViewPDF type="slides" file={slides} />
			<Text variant="title" align="left" gutterBottom>Reflection</Text>
			<Markdown source={reflection} />
		</Container>
	)
};

export default GroupSpeech;
