import * as React from "react";
import { hot } from "react-hot-loader";

import { Focus } from "@curi/react-dom";
import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";

import * as bgImage from "assets/img/background.png";
import Footer from "components/layout/Footer";
import Header from "components/layout/Header";
import { contentResponsiveWidthBreakpoints } from "utils/style";

const styles = ({ spacing, breakpoints, palette }: Theme) => createStyles({
	main: {
		outline: 0, // why is this even needed? does having tabIndex on main give it the focus outline functionality?
		backgroundColor: palette.background.default,
		backgroundImage: `url(${bgImage})`,
		backgroundRepeat: "repeat",
		minHeight: "100vh",
		display: "flex",
		justifyContent: "center",
		position: "relative",
	},
	// background dim
	mainUnderlay: {
		// position: "absolute",
		// top: 0,
		// left: 0,
		// bottom: 0,
		// right: 0,
		// backgroundColor: "rgba(0, 0, 0, 0.5)",
		// zIndex: 2
	},
	content: {
		padding: spacing.unit * 4,
		flexGrow: 1,
		zIndex: 3,
		maxWidth: "100%",
		[breakpoints.down("xs")]: {
			padding: spacing.unit * 2
		},
		...contentResponsiveWidthBreakpoints(breakpoints),
	}
});

type OwnProps = {
	body: React.ComponentType
};
type Props = OwnProps & WithStyles<typeof styles>;
type State = {};

class App extends React.Component<Props, State> {

	// if App updates, it's probably a route change, so unfocus the selected Link
	componentDidUpdate(prevProps: Props) {

		// if (prevProps.location !== this.props.location) {
		// 	// maybe hacky, but works
		// 	const el = document.activeElement;
		// 	if (el instanceof HTMLElement) el.blur();

		// 	window.scrollTo(0, 0);
		// }
	}

	render() {

		const { classes, body: Body } = this.props;

		return (
			<>
				<Header />
				<Focus preventScroll={true}>
					{(ref) => (
						<main className={classes.main} tabIndex={-1} ref={ref}>
							<div className={classes.mainUnderlay} />
							<div className={classes.content}>
								<Body />
							</div>
						</main>
					)}
				</Focus>
				<Footer />
			</>
		)
	}
}

const HotApp = process.env.NODE_ENV === "production" ? App : hot(module)(App);

export default withStyles(styles)(HotApp);
