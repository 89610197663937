import * as React from "react";

import * as resume from "assets/resume.pdf";
import Container from "components/common/Container";
import Text from "components/common/Text";
import ViewPDF from "components/common/ViewPDF";

const Resume = () => {
	return (
		<Container>
			<Text variant="display2" align="center" gutterBottom>My Résumé</Text>
			<ViewPDF type="document" file={resume} />
		</Container>
	)
};

export default Resume;
