import * as React from "react";

import { Button } from "@material-ui/core";

import * as vaacme from "assets/md/vaacme-work.md";
import Container from "components/common/Container";
import Text from "components/common/Text";
import Markdown from "components/layout/Markdown";


const Work = () => {
	return (
		<Container>
			<Text variant="display2" align="center" gutterBottom>Outside Organizations</Text>
			<Text variant="title">VA-ACME</Text>
			<Text variant="subheading" gutterBottom>Virginia Advisory Council on Military Education, Inc.</Text>
			<Markdown source={vaacme} />
			<Text variant="headline" gutterBottom>Check out their site!</Text>
			<Button href="https://vaacme.org/" target="_blank" variant="contained" size="large" color="primary">vaacme.org</Button>
		</Container>
	)
};

export default Work;
