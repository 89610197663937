import * as React from "react";

import Container from "components/common/Container";
import Text from "components/common/Text";

const NotFound = () => {
	return (
		<Container>
			<Text variant="display2" align="center">NotFound!</Text>
			<Text variant="body1">Welcome NotFound!</Text>
		</Container>
	)
};

export default NotFound;
