import * as React from "react";

import { Active, Curious, Link } from "@curi/react-dom";
import {
    AppBar,
    createStyles,
    Drawer,
    Hidden,
    IconButton,
    List,
    ListItem,
    ListItemText,
    Tab,
    Tabs,
    Theme,
    Toolbar,
    WithStyles,
    withStyles,
} from "@material-ui/core";
import Menu from "mdi-material-ui/Menu";

import Text from "components/common/Text";
import { navItems } from "components/routes/config";
import config from "utils/config";

const styles = ({ spacing, palette, breakpoints }: Theme) => createStyles({
	header: {
		backgroundColor: palette.primary.main,
		zIndex: 60,
		position: "relative"
	},
	tabRoot: {
		flexGrow: 1 // for centering
	},
	title: {
		padding: spacing.unit * 2,
		// color: palette.text.primary,
		color: palette.common.white
	},
	appBar: {
		flexDirection: "row",
		zIndex: 50,
		position: "relative",
		[breakpoints.down("xs")]: {
			minHeight: 64 // same as min-height for "sm"
		}
	},
	sideMenuList: {
		width: 250
	},
	grow: {
		flexGrow: 1
	},
	tab: {
		height: 52,
		[breakpoints.down("md")]: {
			minWidth: 120 // default 160
		},
		[breakpoints.down("sm")]: {
			minWidth: 80 // default 160
		}
	},
	tabLabel: {
		[breakpoints.down("md")]: {
			paddingLeft: spacing.unit * 1,
			paddingRight: spacing.unit * 1
		}
	},
	menuButton: {
		marginRight: spacing.unit * 2
	},
	toolbar: {
		justifyContent: "flex-end",
		flexGrow: 1
	}
});

type State = {
	sidebarOpen: boolean
};

type Props = WithStyles<typeof styles>;

const linkComponentCache = new Map<string, React.SFC>();
const makeLink = (path: string) => {
	if (linkComponentCache.has(path))
		return linkComponentCache.get(path);
	else {
		const value = (props: any) => <Link {...props} to={path} />
		linkComponentCache.set(path, value);
		return value;
	}
};

class Header extends React.Component<Props, State> {

	state: State = {
		sidebarOpen: false
	};

	openSideMenu = () => { this.setState({ sidebarOpen: true }); }
	closeSideMenu = () => { this.setState({ sidebarOpen: false }); }

	render() {
		const { classes } = this.props;
		const { sidebarOpen } = this.state;

		return (
			<>
				<Drawer open={sidebarOpen} onClose={this.closeSideMenu}>
					<div tabIndex={0} role="button" onClick={this.closeSideMenu} onKeyDown={this.closeSideMenu}>
						<List className={classes.sideMenuList} component="nav">
							{
								navItems.map(({ name }) => (
									<Active name={name} key={name}>
										{(active) => (
											<ListItem component={makeLink(name)} selected={active}>
												<ListItemText primary={name} />
											</ListItem>
										)}
									</Active>
								))
							}
						</List>
					</div>
				</Drawer>
				<Hidden xsDown>
					<header className={classes.header}>
						<Text className={classes.title} variant="display2" align="center">{config.siteName}</Text>
					</header>
				</Hidden>
				<AppBar className={classes.appBar} position="static" component="nav">
					<Hidden smUp>
						<Toolbar className={classes.grow}>
							<IconButton
								onClick={this.openSideMenu}
								className={classes.menuButton}
								color="inherit"
							>
								<Menu />
							</IconButton>
							<Text className={classes.grow} variant="title" color="inherit">{config.siteName}</Text>
						</Toolbar>
					</Hidden>
					<Hidden xsDown>
						<Curious>
							{({ response }) => (
								<Tabs value={response.name} className={classes.tabRoot} centered>
									{
										navItems.map(({ name }) => {
											return (
												<Tab
													key={name}
													value={name}
													label={name}
													classes={{ labelContainer: classes.tabLabel }}
													className={classes.tab}
													component={makeLink(name)}
												/>
											);
										})
									}
								</Tabs>
							)}
						</Curious>
					</Hidden>
				</AppBar>
			</>
		)
	}
}

export default withStyles(styles)(Header);
