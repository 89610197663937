import * as React from "react";

import { createStyles, Theme, Typography, WithStyles, withStyles } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";

const styles = ({ spacing }: Theme) => createStyles({
	gutterBottom: {
		marginBottom: spacing.unit * 2
	}
});

export type TextProps = TypographyProps;

type Props = TypographyProps & WithStyles<typeof styles>;

const Text: React.SFC<Props> = ({ classes, children, ...rest }) => (
	<Typography classes={classes} {...rest}>{children}</Typography>
);

export default withStyles(styles)(Text);
