import * as React from "react";

import { createStyles, Paper, Theme, WithStyles, withStyles } from "@material-ui/core";
import cn from "classnames";

const styles = ({ spacing, breakpoints }: Theme) => createStyles({
	paper: {
		padding: spacing.unit * 3
	}
});

type Props = {
	className?: string
};

type AllProps = Props & WithStyles<typeof styles>;

const Container: React.SFC<AllProps> = ({ classes, children, className, ...rest }) => (
	<Paper className={cn(classes.paper, className)} {...rest}>
		{children}
	</Paper>
);

export default withStyles(styles)(Container);
