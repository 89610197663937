import * as React from "react";

import { createStyles, Grid, Theme, WithStyles, withStyles } from "@material-ui/core";
import GithubCircle from "mdi-material-ui/GithubCircle";
import LinkedIn from "mdi-material-ui/LinkedIn";
import Twitch from "mdi-material-ui/Twitch";

import Text from "components/common/Text";
import TooltipIconButton from "components/common/TooltipIconButton";

const links = [
	{
		Icon: GithubCircle,
		url: "https://github.com/jmariner",
		title: "Github"
	},
	{
		Icon: LinkedIn,
		url: "https://www.linkedin.com/in/jmariner/",
		title: "LinkedIn"
	},
	{
		Icon: Twitch,
		url: "https://twitch.tv/justinm53",
		title: "Twitch"
	}
];

const styles = ({ palette, spacing, breakpoints }: Theme) => createStyles({
	root: {
		backgroundColor: palette.primary.main,
		color: palette.primary.contrastText,
		margin: 0,
		padding: `0 ${spacing.unit * 2}px`,
		[breakpoints.down("xs")]: {
			padding: `${spacing.unit * 2}px 0`,
		},
		width: "100%"
	},
	link: {
		color: "inherit"
	},
	left: {
		justifyContent: "flex-start",
		[breakpoints.down("xs")]: {
			justifyContent: "center",
		},
	},
	right: {
		justifyContent: "flex-end",
		[breakpoints.down("xs")]: {
			justifyContent: "center",
		},
	},
	middle: {
		border: "1px none rgba(255, 255, 255, 0.25)",
		borderStyle: "none solid",
		[breakpoints.down("xs")]: {
			borderStyle: "solid none",
			margin: `${spacing.unit * 1}px 0`,
		}
	}
});

type Props = WithStyles<typeof styles>;

const Footer: React.SFC<Props> = ({ classes }) => (
	<Grid container className={classes.root} component="footer" alignItems="center">
		<Grid item container xs={12} sm={4} className={classes.left} alignItems="center">
			<Text color="inherit" variant="body1">© Justin Mariner, 2018</Text>
		</Grid>
		<Grid item container xs={12} sm={4} className={classes.middle} justify="space-around">
			{
				links.map(({ url, title, Icon }, idx) => (
					// index doesn't change at runtime so it's a safe key
					<Grid item key={idx}>
						<TooltipIconButton icon={Icon} href={url} title={title} className={classes.link} />
					</Grid>
				))
			}
		</Grid>
		<Grid item container xs={12} sm={4} className={classes.right} alignItems="center" justify="flex-end">
			<Text color="inherit" variant="body1">Made with React, Material UI, Curi Router, and React-PDF</Text>
		</Grid>
	</Grid>
);

export default withStyles(styles)(Footer);
