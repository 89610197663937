import * as React from "react";

import { Button, createStyles, Grid, Theme, WithStyles, withStyles } from "@material-ui/core";

import * as essay from "assets/individual-essay.pdf";
import * as reflection from "assets/md/individual-essay-reflection.md";
import Container from "components/common/Container";
import Text from "components/common/Text";
import ViewPDF from "components/common/ViewPDF";
import Markdown from "components/layout/Markdown";

// import * as planning from "assets/individual-essay-planning.pdf";

const styles = ({ spacing }: Theme) => createStyles({
	paperHeading: {
		marginBottom: spacing.unit * 2
	},
	left: {
		display: "flex",
		alignItems: "center"
	},
	right: {
		display: "flex",
		justifyContent: "flex-end",
		alignItems: "center"
	}
});

type Props = WithStyles<typeof styles>;

const Essay: React.SFC<Props> = ({ classes }) => {
	return (
		<Container>
			<Text variant="display2" align="center" gutterBottom>Individual Essay</Text>
			<Grid className={classes.paperHeading} container>
				<Grid className={classes.left} item xs={6}>
					<Text variant="title" align="left">Sample Work</Text>
				</Grid>
				<Grid className={classes.right} item xs={6}>
					<Button href="#reflection" variant="contained" color="primary">Jump to Reflection</Button>
				</Grid>
			</Grid>
			<ViewPDF type="document" file={essay} />
			<Text variant="title" align="left" gutterBottom id="reflection">Reflection</Text>
			<Markdown source={reflection} />
		</Container>
	)
};

export default withStyles(styles)(Essay);
