import * as React from "react";
import { render } from "react-dom";
import { Helmet } from "react-helmet";

import "typeface-roboto";
import { CuriProvider } from "@curi/react-dom";
import active from "@curi/route-active";
import { curi } from "@curi/router";
import Browser from "@hickory/browser";
import { createMuiTheme, CssBaseline, MuiThemeProvider } from "@material-ui/core";

import App from "components/App";
import routes from "components/routes/config";
import config from "utils/config";

const { spacing, breakpoints } = createMuiTheme();
const theme = createMuiTheme({
	typography: {
		fontSize: 16
	},
	palette: {
		// text: {
		// 	primary: "rgba(0, 0, 0, 0.94)", // default = 0.87, +0.07
		// 	secondary: "rgba(0, 0, 0, 0.61)", // default = 0.54
		// 	disabled: "rgba(0, 0, 0, 0.45)", // default = 0.38
		// 	hint: "rgba(0, 0, 0, 0.45)", // default = 0.38
		// },
		primary: {
			main: "#673ab7"
		},
		secondary: {
			main: "#ff3d00"
		},
		type: "dark"
	},
	overrides: {
		MuiButtonBase: {
			root: {
				textAlign: "center"
			}
		},
		MuiButton: {
			label: {
				"& > span": {
					display: "inline-block"
				}
			}
		}
	}
});

const history = Browser();
const router = curi(history, routes, {
	route: [active()]
});

const app = (
	<>
		<CssBaseline />
		<MuiThemeProvider theme={theme}>
			<CuriProvider router={router}>
				{({ response }) => {
					const { body, name, title } = response;
					return (
						<>
							<Helmet>
								<title>{config.siteName} - {title !== undefined ? title : name}</title>
							</Helmet>
							<App body={body} />
						</>
					)
				}}
			</CuriProvider>
		</MuiThemeProvider>
	</>
);

render(app, document.getElementById("app"));

export { theme };
