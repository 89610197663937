import * as React from "react";

import * as welcome from "assets/md/welcome.md";
import Container from "components/common/Container";
import Markdown from "components/layout/Markdown";

const Home = () => {
	return (
		<Container>
			<Markdown source={welcome} />
		</Container>
	)
};

export default Home;
