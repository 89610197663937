import * as React from "react";

import * as slides from "assets/individual-presentation-slides.pdf";
import * as reflection from "assets/md/individual-presentation-reflection.md";
import Container from "components/common/Container";
import Text from "components/common/Text";
import ViewPDF from "components/common/ViewPDF";
import Markdown from "components/layout/Markdown";

// import * as outline from "assets/individual-presentation-outline.pdf";
const Speech = () => {
	return (
		<Container>
			<Text variant="display2" align="center" gutterBottom>Individual Presentation</Text>
			<Text variant="title" align="left" gutterBottom>Sample Work</Text>
			<ViewPDF type="slides" file={slides} />
			<Text variant="title" align="left" gutterBottom>Reflection</Text>
			<Markdown source={reflection} />
		</Container>
	)
};

export default Speech;
