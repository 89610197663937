import * as React from "react";

import { IconButton } from "@material-ui/core";
import { IconButtonProps } from "@material-ui/core/IconButton";
import { SvgIconProps } from "@material-ui/core/SvgIcon";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

type Props = IconButtonProps & {
	icon: React.ComponentType<SvgIconProps>,
	title: TooltipProps["title"],
	placement?: TooltipProps["placement"]
};

const TooltipIconButton: React.SFC<Props> = ({ title, placement, icon: IconComp, ...rest }) => (
	<Tooltip title={title} placement={placement}>
		<IconButton {...rest}>
			<IconComp />
		</IconButton>
	</Tooltip>
);

export default TooltipIconButton;
