export default {
	siteName: "Justin's ePortfolio",
	gdriveFiles: {
		resume: "1jP1_HPGgTJfNEpPvSc2b84myLj2F5sEH",
		groupPresentationSlides: "1NanuMkT8E-hVwCdNk7dmj7j2tTDxx5Iq",
		groupPresentationOutline: "1uO6BpiSvo9T8Y12nyBKtnxGz31GSyrDx",
		individualEssay: "1dy5Cu4ndiKRaZyAIXtzo-uTSmIUz6HKJ",
		individualEssayPlanning: "11etxHybAyCz7iGHjPSVZVQcu37hxMrXV",
		individualPresentationOutline: "1DC69wOzN3YDQCimqAgYRYaCQ4oWCnCSo",
		individualPresentationSlides: "1U717m93CcoEP6em_XzG2DI7yyo4yruGO"
	}
};
