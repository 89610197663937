import * as React from "react";
import * as ReactMarkdown from "react-markdown";

import { createStyles, Theme, WithStyles, withStyles } from "@material-ui/core";
import { TypographyProps } from "@material-ui/core/Typography";

import Text from "components/common/Text";

const styles = ({ spacing }: Theme) => createStyles({
	root: {
		padding: `0 ${spacing.unit * 2}px`
	},
	paragraph: {
		textIndent: "3em",
		marginBottom: spacing.unit * 2
	},
	list: {
		// margin: 0
	},
	listItem: {
		display: "list-item"
	}
});
type Styles = WithStyles<typeof styles>;

type ParaProps = Styles;
const Paragraph: React.SFC<ParaProps> = ({ classes, children }) => (
	<Text className={classes.paragraph} variant="body2" align="left" component="p">{children}</Text>
);

const levelVariantMap: TypographyProps["variant"][] = [null, "display2", "display1", "headline", "title", "subheading", "caption"];
type HeadingProps = Styles & { level: number };
const Heading: React.SFC<HeadingProps> = ({ classes, children, level, ...rest }) => (
	<Text variant={levelVariantMap[level]} align={level === 1 ? "center" : "left"} gutterBottom>{children}</Text>
);

type ListProps = Styles & { ordered: boolean, depth: number, start: number, tight: boolean };
const List: React.SFC<ListProps> = ({ classes, children, ordered, ...rest }) => {
	const ListEl = ordered ? "ol" : "ul";
	return (<ListEl className={classes.list}>{children}</ListEl>);
};

type ListItemProps = Styles & { checked: boolean, tight: boolean, ordered: boolean, index: number };
const ListItem: React.SFC<ListItemProps> = ({ classes, children }) => (
	<Text className={classes.listItem} variant="body1" component="li">{children}</Text>
);

const renderers = {
	paragraph: withStyles(styles)(Paragraph),
	heading: withStyles(styles)(Heading),
	list: withStyles(styles)(List),
	listItem: withStyles(styles)(ListItem)
};

type OwnProps = { source: string };
type Props = Styles & OwnProps;

const Markdown: React.SFC<Props> = ({ classes, source }) => (
	<ReactMarkdown
		className={classes.root}
		source={source}
		renderers={renderers}
	/>
);

export default withStyles(styles)(Markdown);
