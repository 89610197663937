import * as React from "react";

import { createStyles, Grid, Theme, WithStyles, withStyles } from "@material-ui/core";

// Source: https://codepen.io/mrrocks/pen/EiplA
const styles = ({ palette }: Theme) => createStyles({
	spinner: {
		animation: "Loading-rotator 1.4s linear infinite"
	},
	path: {
		strokeDasharray: 187,
		strokeDashoffset: 0,
		transformOrigin: "center",
		animation: "Loading-dash 1.4s ease-in-out infinite, Loading-colors 5.6s ease-in-out infinite"
	},
	"@keyframes Loading-rotator": {
		"0%": { transform: "rotate(0deg)" },
		"100%": { transform: "rotate(270deg)" }
	},
	"@keyframes Loading-colors": {
		"0%": { stroke: "#4285F4" },
		"25%": { stroke: "#DE3E35" },
		"50%": { stroke: "#F7C223" },
		"75%": { stroke: "#1B9A59" },
		"100%": { stroke: "#4285F4" }
	},
	"@keyframes Loading-dash": {
		"0%": { strokeDashoffset: 187 },
		"50%": {
			strokeDashoffset: 46.75,
			transform: "rotate(135deg)"
		},
		"100%": {
			strokeDashoffset: 187,
			transform: "rotate(450deg)"
		}
	},
});

type OwnProps = {
	size?: number
};

type Props = OwnProps & WithStyles<typeof styles>;

const Loading: React.SFC<Props> = ({ size, classes }) => (
	<Grid container justify="center">
		<svg className={classes.spinner} width={`${size}px`} height={`${size}px`} viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
			<circle className={classes.path} fill="none" strokeWidth="6" strokeLinecap="round" cx="33" cy="33" r="30"></circle>
		</svg>
	</Grid>
);

Loading.defaultProps = {
	size: 50
};

export default withStyles(styles)(Loading);
