import { Breakpoint, Breakpoints } from "@material-ui/core/styles/createBreakpoints";

const contentWidthOffset = 20;
const widths = ["sm", "md"]/*, "lg"]*/ as Breakpoint[];

type WidthsMap = {
	[key in Breakpoint]: number
};

export const getContentWidth = (breakpoints: Breakpoints) => (bp: Breakpoint) => breakpoints.width(bp) - contentWidthOffset;

const makeWidths = (offset: number) => (breakpoints: Breakpoints) => widths.reduce((acc, bp: Breakpoint) => ({
	...acc,
	[bp]: breakpoints.width(bp) - offset
}), {} as WidthsMap);


export const contentResponsiveWidths = makeWidths(contentWidthOffset);

const makeBreakpointWidths = (widthsMap: WidthsMap) => (breakpoints: Breakpoints) => Object.keys(widthsMap).reduce((acc, bp: Breakpoint) => ({
	...acc,
	[breakpoints.up(bp)]: { maxWidth: widthsMap[bp] }
}), {});

export const contentResponsiveWidthBreakpoints = (breakpoints: Breakpoints) => makeBreakpointWidths(contentResponsiveWidths(breakpoints))(breakpoints);
