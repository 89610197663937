import { RouteDescriptor } from "@curi/router";

import Essay from "components/routes/Essay";
import GroupSpeech from "components/routes/GroupSpeech";
import Home from "components/routes/Home";
import NotFound from "components/routes/NotFound";
import Reflection from "components/routes/Reflection";
import Resume from "components/routes/Resume";
import Speech from "components/routes/Speech";
import Work from "components/routes/Work";

export type RouteDescriptorEx = {
	hideNav?: boolean
} & RouteDescriptor;

export enum Routes {
	HOME = "Home",
	RESUME = "Résumé",
	WORK = "Work",
	ESSAY = "Essay",
	SPEECH = "Speech",
	GROUP_SPEECH = "Group Speech",
	REFLECTION = "Reflection",
	NOT_FOUND = "Not Found"
};

const routes: RouteDescriptorEx[] = [
	{
		name: Routes.HOME,
		path: "",
		response: () => ({ body: Home })
	},
	{
		name: Routes.RESUME,
		path: "resume",
		response: () => ({ body: Resume, title: "My Résumé" })
	},
	{
		name: Routes.WORK,
		path: "work",
		response: () => ({ body: Work, title: "Outside Organizations" })
	},
	{
		name: Routes.ESSAY,
		path: "essay",
		response: () => ({ body: Essay, title: "Individual Essay" })
	},
	{
		name: Routes.SPEECH,
		path: "speech",
		response: () => ({ body: Speech, title: "Individual Presentation / Speech" })
	},
	{
		name: Routes.GROUP_SPEECH,
		path: "group-speech",
		response: () => ({ body: GroupSpeech, title: "Group Presentation / Speech" })
	},
	{
		name: Routes.REFLECTION,
		path: "reflection",
		response: () => ({ body: Reflection, title: "Overall Education Reflection" })
	},
	{
		name: Routes.NOT_FOUND,
		path: "(.*)",
		response: () => ({ body: NotFound }),
		hideNav: true
	}
];

export const navItems = routes.filter((r) => !r.hideNav);

export default routes;
